<template>
  <v-sheet class="mx-md-5 mx-2">
    <v-expansion-panels v-model="showSettings">
      <v-expansion-panel>
        <v-expansion-panel-header class="d-flex justify-space-between">
          <div class="d-flex flex-column flex-md-row">
            <span class="mb-2 mb-md-0">{{$vuetify.lang.t('$vuetify.summary.reportParameters')}}</span>
            <span
              v-if="showSettings != 0"
              :class="`ml-0 ml-md-5 ${isMobile ? 'text-caption' : ''}`"
            >
              {{ parameters.startDate | formatDateYear }} -
              {{ parameters.endDate | formatDateYear }}</span
            >
          </div>
          <div
            v-if="showSettings != 0 && !loadingData"
            class="d-flex justify-end mr-2 mr-md-10"
          >
            <v-btn v-if="isMobile" @click.stop="download" icon
              ><v-icon>mdi-download</v-icon></v-btn
            >
            <v-btn v-else @click.stop="download"
              >{{$vuetify.lang.t('$vuetify.base.download')}} <v-icon right>mdi-download</v-icon></v-btn
            >
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-sheet>
            <search-parameters v-model="parameters" />
            <div class="text-center">
              <v-btn
                :disabled="loadingData"
                :loading="loadingData"
                color="primary"
                @click="clickSubmit"
                >{{$vuetify.lang.t('$vuetify.base.submit')}}</v-btn
              >
            </div>
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-sheet class="mt-5" :elevation="2">
      <v-progress-linear indeterminate color="accent" v-if="loadingData" />
      <v-text-field
        v-if="isMobile && !loadingData"
        class="mx-5 pt-5"
        clearable
        label="Filter by Name"
        v-model="filterProduct"
      />
      <v-simple-table
        id="main-table"
        v-if="!loadingData && startBalanceService.date"
        height="90vh"
        style="table-layout: fixed"
      >
        <date-tree-header
          v-model="openDates"
          :startDate="parameters.startDate"
          :endDate="parameters.endDate"
          :prefix="['Program']"
          :postfix="[]"
          :hideExpanableColumns="!displays.showEvents"
          sticky
        >
          <template v-slot:before="{ dateHeaders }">
            <th
              :rowspan="
                dateHeaders && dateHeaders.length > 0 ? dateHeaders.length : 1
              "
              style="z-index: 4"
            >
              <div class="d-flex justify-space-between">
                <v-text-field
                  v-if="!isMobile"
                  style="min-width: 125px"
                  class="mr-2 mr-md-5"
                  clearable
                  label="Filter by Name"
                  v-model="filterProduct"
                />
                <display-filter class="mt-4" v-model="displays" />
              </div>
            </th>
            <th
              :rowspan="
                dateHeaders && dateHeaders.length > 0 ? dateHeaders.length : 1
              "
              style="z-index: 3"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{
                    startBalanceService.date | formatDateYear
                  }}</span>
                </template>
                <span
                  >Inventory balance at the start of
                  {{ startBalanceService.date | formatDateYear }}</span
                >
              </v-tooltip>
            </th>
          </template>
          <template v-slot:after="{ dateHeaders }">
            <th
              :rowspan="
                dateHeaders && dateHeaders.length > 0 ? dateHeaders.length : 1
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{
                    endBalanceService.date | formatDateYear
                  }}</span>
                </template>
                <span
                  >Inventory balance at the end of
                  {{ endBalanceService.date | formatDateYear }}</span
                >
              </v-tooltip>
            </th>
          </template>
        </date-tree-header>
        <tbody>
          <!-- all products row -->
          <tr>
            <td @click="openCloseAllProducts">
              <v-icon v-if="showProductGroup">
                {{
                  openAllState == "open"
                    ? "mdi-chevron-double-down"
                    : "mdi-chevron-double-right"
                }}
              </v-icon>
              All Products
            </td>
            <td class="currency">
              <span v-if="displays.unit == 'value'">
                {{ startBalanceService.totalValue | currency }}
              </span>
              <span v-else>
                {{ startBalanceService.totalQuantity | formatNumber }}
              </span>
            </td>
            <td
              v-show="displays.showEvents"
              class="event-cell"
              v-for="range in openDates"
              :key="submitTime + '_' + allProduct.id + '_' + range.id"
            >
              <inventory-event
                @clickViewDetails="viewCellDetails(allProduct, range)"
                :display="displays.unit"
                :product="allProduct"
                :dateRange="range"
                :key="submitTime + '_' + allProduct.id + '_' + range.id"
              />
            </td>
            <td>
              <span v-if="displays.unit == 'value'">
                {{ endBalanceService.totalValue | currency }}
              </span>
              <span v-else>
                {{ endBalanceService.totalQuantity | formatNumber }}
              </span>
            </td>
          </tr>
          <template v-for="product in products">
            <tr class="product" v-if="product.visible" :key="product.id">
              <td class="product-name">
                <div class="d-flex justify-space-between">
                  <div
                    :class="'product-name mt-2 ml-' + (product.level - 1) * 2"
                  >
                    <v-icon
                      @click="toggleProductGroup(product)"
                      v-if="product.hasChildren"
                    >
                      {{
                        product.state == "open"
                          ? "mdi-chevron-down"
                          : "mdi-chevron-right"
                      }}
                    </v-icon>
                    <text-highlight
                      v-if="filterProduct"
                      :queries="filterProduct"
                    >
                      {{ product.name }}</text-highlight
                    >
                    <span v-else> {{ product.name }}</span>
                  </div>

                  <v-menu right offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <v-list class="px-2" dense min-width="150px">
                      <v-list-item-group>
                        <v-list-item @click="generateChart(product)">
                          <v-list-item-icon
                            ><v-icon>mdi-chart-line</v-icon></v-list-item-icon
                          >
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ product.name }} Chart</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </td>
              <td class="start-balance">
                <a v-if="startBalanceService" @click="showInventoryList(product, startBalanceService)">
                  <span v-if="displays.unit == 'value'"
                    >{{
                      startBalanceService.getBalanceByProduct(product)
                        .remainingValue | currency
                    }}
                  </span>
                  <span v-else>
                    {{
                      startBalanceService.getBalanceByProduct(product).quantity
                        | formatNumber
                    }}
                  </span>
                </a>
              </td>
              <td
                v-show="displays.showEvents"
                class="event-cell"
                v-for="range in openDates"
                :key="submitTime + '_' + product.id + '_' + range.id"
              >
                <inventory-event
                  v-if="product.visible"
                  @clickViewDetails="viewCellDetails(product, range)"
                  :display="displays.unit"
                  :product="product"
                  :dateRange="range"
                  :key="submitTime + '_' + product.id + '_' + range.id"
                />
              </td>
              <td class="end-balance">
                <a @click="showInventoryList(product, endBalanceService)">
                  <span v-if="displays.unit == 'value'">
                    {{
                      endBalanceService.getBalanceByProduct(product)
                        .remainingValue | currency
                    }}
                  </span>
                  <span v-else>
                    {{
                      endBalanceService.getBalanceByProduct(product).quantity
                        | formatNumber
                    }}
                  </span>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <!-- Inventory events details -->
      <custom-dialog
        :fullscreen="true"
        v-if="selectedSlot"
        v-model="showDetails"
      >
        <template slot="title">
          {{ selectedSlot.product }}
          {{
            isMobile ? "" : "- Inventory Events on " + selectedDateRange.label
          }}
        </template>
        <template slot="subtitle">
          <div v-if="isMobile">
            Inventory Events on {{ selectedDateRange.label }}
          </div>
        </template>
        <template slot="default">
          <inventory-events-detail
            :summaryByProductTime="selectedSlot"
            :key="clickTime + '-' + selectedSlot.key"
            :date="selectedDateRange.endTime"
          />
        </template>
      </custom-dialog>

      <!-- Inventory balance details -->
      <custom-dialog
        :fullscreen="true"
        v-if="selectedProduct"
        v-model="inventoryListDialog"
      >
        <template v-if="isMobile" slot="title">
          {{ selectedProduct.product }}
        </template>
        <template v-else slot="title">
          {{ selectedProduct.product }} - Inventory balance on
          {{ selectedDate | formatDateYear }}
        </template>
        <template v-if="isMobile" slot="subtitle">
          Inventory balance on {{ selectedDate | formatDateYear }}
        </template>
        <template slot="default">
          <inventory-balance
            :summaryByProductTime="selectedProduct"
            :key="clickTime + '_' + selectedProduct.id"
            :date="selectedDate"
          />
        </template>
      </custom-dialog>

      <!-- Product date distribution chart -->
      <custom-dialog
        :fullscreen="true"
        v-if="selectedProduct"
        v-model="productChartDialog"
      >
        <template slot="title"> {{ selectedProduct.name }} - Chart </template>
        <template slot="default">
          <date-distribution-chart
            :key="clickTime + '_' + selectedProduct.id + '_' + openDates.length"
            :product.sync="selectedProduct"
            :dates="openDates"
          />
        </template>
      </custom-dialog>
    </v-sheet>

    <div v-if="!loadingData && products" class="pa-4 text-center">
      <!-- Audit Data Integrity-->
      <v-btn class="ma-2" @click="auditDataIntegrity"> Audit Data </v-btn>
      <div class="text-caption"> Check each row's starting balance plus event changes equals ending balance </div>

      <div v-html="console" v-if="console" ref="console" class="text-caption pa-5 console text-left" />
    </div>

  </v-sheet>
</template>

<script>
import Vue from "vue";
import { rules } from "@/store/util/rules.js";
import { mapGetters, mapActions } from "vuex";
import InventoryEventGroup from "@/services/InventoryEventGroup.js";
import DownloadService from "@/services/DownloadService.js";
import moment from "moment";
import TextHighlight from "vue-text-highlight";
import DateTreeHeader from "../../components/common/DateTreeHeader.vue";
const CustomDialog = () => import("@/components/common/CustomDialog.vue");

const InventoryEvent = () =>
  import("@/components/inventory/InventoryEvent.vue");
const InventoryEventsDetail = () =>
  import("@/components/inventory/InventoryEventsDetail.vue");
const InventoryBalance = () =>
  import("@/components/inventory/InventoryBalance.vue");
const DisplayFilter = () => import("@/components/inventory/DisplayFilter.vue");
const SearchParameters = () =>
  import("@/components/inventory/SearchParameters.vue");
const DateDistributionChart = () =>
  import("@/components/inventory/DateDistributionChart.vue");

export default {
  data: () => ({
    displays: {
      unit: "quantity",
      showEvents: true,
      withDeltaOnly: false,
      showProductsWithEvents: false,
      showProductGroup: true,
    },
    console: '',
    deltaOnly: false,
    hasEventsOnly: false,
    selectedProduct: null,
    selectedDate: null,
    selectedDateRange: null,
    selectedSlot: null,
    showDetails: false,
    showProductGroup: true,
    inventoryListDialog: false,
    productChartDialog: false,
    filterProduct: null,
    showSettings: 0,
    loadingData: false,
    hasEvents: false,
    openDates: [],
    rules,
    submitTime: Date.now(),
    clickTime: Date.now(),
  }),
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("inventoryBalances", [
      "startBalanceService",
      "endBalanceService",
    ]),
    ...mapGetters("inventoryEvents", ["eventsService", "eventsDateRange"]),
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
    ...mapGetters("processes", ["processTypes"]),
    ...mapGetters("products", ["products", "openAllState"]),
    parameters: {
      get() {
        return this.$store.state.inventoryParameters.parameters;
      },
      set(value) {
        this.updateParameters(value);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  watch: {
    filterProduct: function (value) {
      if (this.displays.showProductGroup && !value) {
        this.closeAllProducts();
      } else {
        this.filterDisplayProducts();
      }
    },
    displays: {
      handler(newVal) {
        if (newVal.withDeltaOnly != this.deltaOnly) {
          this.filterDisplayProducts();
          this.deltaOnly = newVal.withDeltaOnly;
        }
        if (newVal.showProductsWithEvents != this.hasEventsOnly) {
          this.filterDisplayProducts();
          this.hasEventsOnly = newVal.showProductsWithEvents;
        }
        if (newVal.showProductGroup != this.showProductGroup) {
          this.filterDisplayProducts();
          this.showProductGroup = newVal.showProductGroup;
        }
      },
      deep: true,
    },
  },
  components: {
    InventoryEvent,
    InventoryEventsDetail,
    InventoryBalance,
    DisplayFilter,
    SearchParameters,
    TextHighlight,
    DateTreeHeader,
    CustomDialog,
    DateDistributionChart,
  },
  mounted() {
    this.allProduct = {
      id: 0,
      label: "All Products",
      name: "All Products",
      leafNodeIds: [],
      level: 0,
      visible: true,
      hasChildren: true,
      path: [0],
    };
    this.loadProducts().then(() => {
      //create root product
      const level1Products = this.products.filter((p) => p.level == 1);
      level1Products.forEach((p) => {
        this.allProduct.leafNodeIds = [
          ...this.allProduct.leafNodeIds,
          ...p.leafNodeIds,
        ];
      });
    });

    this.loadProcessTypes();
    this.fetchCustomers({page: 0, size: 10000});
    this.fetchSuppliers({page: 0, size: 10000});
  },
  methods: {
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
      "clearMessage",
      "addInfoMessage",
      "setMsgTimeout",
    ]),
    ...mapActions("processes", ["loadProcessTypes"]),
    ...mapActions("inventoryParameters", [
      "updateParameters",
      "setProcessTypes",
    ]),
    ...mapActions("inventoryBalances", ["loadInventoryBalance"]),
    ...mapActions("inventoryEvents", ["loadInventoryEvents"]),
    ...mapActions("inventoryCostAdj", ["loadInventoryCostAdj"]),
    ...mapActions("products", [
      "loadProducts",
      "toggleProductGroup",
      "openCloseAllProducts",
      "showProductIds",
      "closeAllProducts",
      "openAllProducts",
      "showHideProductGroups",
    ]),
    ...mapActions("navigation", ["hideDrawer"]),
    ...mapActions("company", ["fetchCustomers", "fetchSuppliers"]),
    async initData() {
      this.loadingData = true;

      this.setMsgTimeout(10000); //set message timeout to 10 seconds
      this.addInfoMessage("Loading inventory balances...");

      const startDate = moment(this.parameters.startDate).subtract(1, "days").endOf('day');
      const endDate = moment(this.parameters.endDate).endOf('day')
      const balanceService = await this.loadInventoryBalance({
        startDate,
        endDate,
        includeCost: true
      });

      //Get inventory events
      this.addInfoMessage("Loading inventory events...");
      const loadedEvents = await this.loadInventoryEvents({
        startDate: balanceService.startBalanceService
          ? balanceService.startBalanceService.date
          : null,
        endDate: balanceService.endBalanceService
          ? balanceService.endBalanceService.date
          : null,
        types: this.parameters.eventTypes,
        processTypes: this.parameters.processTypes,
        customers: this.parameters.customers,
        suppliers: this.parameters.suppliers,
      });

      if (!loadedEvents || loadedEvents.length == 0) {
        this.hasEvents = false;
        const start = balanceService.startBalanceService.date;
        const end = balanceService.endBalanceService
          ? this.endBalanceService.date
          : start;
        if (start === end) {
          this.addMessage(
            "No inventory event found on " + moment(start).format("MMM DD, YY")
          );
        } else {
          this.addMessage(
            "No inventory event found between " +
              moment(start).format("MMM DD, YY") +
              " and " +
              moment(end).format("MMM DD, YY")
          );
        }
      } else {
        this.hasEvents = true;
      }

      this.addInfoMessage("Loading inventory cost adjustments, please wait...");
      await this.loadInventoryCostAdj({
        date: balanceService.endBalanceService
          ? balanceService.endBalanceService.date
          : balanceService.startBalanceService.date,
      });

      // this.addInfoMessage("Loading packaging weight adjustments, please wait...")
      // await this.loadPackingWeightAdj();

      this.loadingData = false;
      this.clearMessage();
    },
    viewCellDetails(productGroup, dateRange) {
      const group = new InventoryEventGroup(
        productGroup,
        dateRange.startTime,
        dateRange.endTime
      );
      this.selectedSlot = this.eventsService.getGroupedEvents(
        group,
        this.costAdjustmentService
      );
      console.log("clicked cell: ", this.selectedSlot.key);
      this.selectedDateRange = dateRange;
      this.clickTime = Date.now();
      this.showDetails = true;
    },
    generateChart(product) {
      console.log("generating for product: ", product);
      this.selectedProduct = product;
      this.productChartDialog = true;
    },
    filterDisplayProducts() {
      let filteredProducts = this.products;

      //Open up all product group so the leaf products are visible
      if (!this.displays.showProductGroup) {
        this.openAllProducts();
      }

      let hasFiltered = false;
      //Show products with changes between two start and end
      if (this.displays.withDeltaOnly) {
        filteredProducts = filteredProducts.filter((p) =>
          this.balanceChanged(p)
        );
        hasFiltered = true;
      }

      if (this.filterProduct) {
        filteredProducts = filteredProducts.filter((product) =>
          product.name.toLowerCase().includes(this.filterProduct.toLowerCase())
        );
        hasFiltered = true;
      }

      let showProductIds = [];
      if (hasFiltered) {
        showProductIds = filteredProducts.map((product) => product.id);
      } else {
        showProductIds = this.products
          .filter((product) => product.visible)
          .map((product) => product.id);
      }

      if (this.displays.showProductsWithEvents && this.eventsService) {
        let eventProductIds = this.eventsService.getEventProductIds();
        eventProductIds = eventProductIds.map((product) => parseInt(product));
        //filter out product group; only want leaf products
        // eventProductIds = eventProductIds.filter(pid => filteredProducts.find( fp => !fp.hasChildren && fp.id == pid))
        showProductIds = showProductIds.filter((id) =>
          eventProductIds.includes(id)
        );
      }

      //filter out product groups
      if (!this.displays.showProductGroup) {
        showProductIds = showProductIds.filter((id) => {
          const product = this.products.find((p) => p.id == id);
          if (product.hasChildren) {
            return false;
          } else {
            return true;
          }
        });
      }

      this.showProductIds({
        productIds: showProductIds,
        showGroup: this.displays.showProductGroup,
      });
    },
    // toggleProductGroupsDisplay(){
    //   //show or hide product groups
    //   this.showHideProductGroups(this.displays.showProductGroup)
    // },
    clickSubmit() {
      this.submitTime = Date.now();

      //check parameter restrictions; limit to 1 years data search
      const startDate = moment(this.parameters.startDate);
      const endDate = moment(this.parameters.endDate);

      const diffInDays = startDate.diff(endDate, "days");
      if (Math.abs(diffInDays) > 400) {
        this.addMessage("Please keep date search within a year");
        return;
      }

      this.initData();
      this.showSettings = null;
      this.filterDisplayProducts();
      this.hideDrawer();
    },
    showInventoryList(product, balanceService) {
      this.selectedProduct = balanceService.getBalanceByProduct(product);
      this.selectedDate = balanceService.getDate();
      this.inventoryListDialog = true;
    },
    balanceChanged(product) {
      const startBalance =
        this.startBalanceService.getBalanceByProduct(product);
        
      const startValue = startBalance ? startBalance.remainingValue : 0;
      const startQuantity = startBalance ? startBalance.quantity : 0;

      const endBalance = this.endBalanceService.getBalanceByProduct(product);
      const endValue = endBalance ? endBalance.remainingValue : 0;
      const endQuantity = endBalance ? endBalance.quantity : 0;

      return (
        startQuantity != endQuantity ||
        Vue.filter("currency")(startValue) !== Vue.filter("currency")(endValue)
      );
    },
    download() {
      try {
        new DownloadService().downloadInventorySummary(
          this.products,
          this.openDates,
          this.eventsService,
          this.costAdjustmentService,
          this.startBalanceService,
          this.endBalanceService
        );
      } catch (e) {
        this.addErrorMessage(e);
      }
    },
    auditDataIntegrity(){
      this.console = ''
      this.appendConsole('Starting summary report balance check...')
      //run post rendering check
      const rows = document.querySelectorAll('tr.product')
      this.appendConsole(`Found ${rows ? rows.length:0} rows to audit`);
      if(rows){
        rows.forEach(row => {
          const product = row.querySelector('.product-name');
          const productName = product ? product.innerText:'N/A Product';
          const start = row.querySelector('.start-balance');
          const end = row.querySelector('.end-balance');
          const  events = row.querySelectorAll('.event-cell');
          let eventTotal = 0;
          if(events){
            eventTotal = Array.from(events).reduce( (sum, e) => {
              const cellText = e.innerText ? e.innerText.replace(/[^0-9.-]+/g,""):'';
              const val = cellText && !isNaN(cellText) ? parseFloat(cellText):0;
              return sum += val;
            }, 0);
          }

          const startText = start.innerText.replace(/[^0-9.-]+/g,"");
          const endText = end.innerText.replace(/[^0-9.-]+/g,"");
          const startBalance = !isNaN(startText) ? parseFloat(startText):0;
          const endBalance = !isNaN(endText) ? parseFloat(endText):0;
          const difference = Math.abs(endBalance) - Math.abs(startBalance);

          const diff = (difference - eventTotal).toFixed(0);
          if(diff == 0){
            this.appendConsole(`Checking ${productName}: Passed; ${diff} `)
          }
          else{
            this.appendError(`Checking ${productName}: Failed; ${diff} difference detected}`)
          }
        })
      }
    },
    appendConsole(text) {
      this.console += "\n" + text;
    },
    appendError(text) {
      this.console += "\n<span class='console-error'>" + text + "</span>"
    },
  },
};
</script>

<style lang="scss">
#main-table {
  position: relative;
}

#main-table tbody td:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 3;
  opacity: 0.95;
  background-color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

tbody td {
  white-space: nowrap;
}

td.event-cell {
  padding: 0px !important;
}

.product-full {
  width: 25vh;
}
.product-compact {
  width: 10vh;
}

@media only screen and (max-width: 480px) {
  .product-name {
    display: inline-block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

.console {
  border: 1px solid #ccc;
  max-height: 600px;
  overflow: auto;
  white-space: pre-line;
}
.console-error{
  color: red;
}
</style>
